import type { AppProps } from 'next/app'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { UserProvider } from '@auth0/nextjs-auth0/client';

const theme = createTheme({
  palette: {
    primary: {
      main: '#407BFF'
    },
    info: {
      main: '#FFFFFF'
    }
  },
  typography: {
    fontFamily: 'Mulish, sans-serif'
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  return(
    <UserProvider>
      <ThemeProvider theme={theme}>
        <Component {...pageProps} />
      </ThemeProvider>
    </UserProvider>
  )
}

export default MyApp
